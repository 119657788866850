import { Link } from 'react-router-dom';
import styles from './memberList.module.css';
import { Row } from 'antd';

interface MemberItemPropType {
  cusOrgId: string;
  name: string;
  email: string;
  avatar?: string;
  orgId?: string;
}

export default function MemberItem(props: MemberItemPropType) {
  const { cusOrgId, name, email, avatar, orgId } = props;
  return (
    <Link
      className={styles.member_item}
      to={`/organization-list/organization-info/${orgId}/member-info/${cusOrgId}`}
    >
      <Row style={{ alignItems: 'center' }}>
        <img
          className={styles.member_avatar}
          src={avatar ? avatar : '/Images/default-avatar.png'}
          alt=''
          onError={(e) => {
            (e.target as HTMLImageElement).src = '/Images/default-avatar.png';
          }}
        />
        <div>
          <div className={styles.member_name}>{name}</div>
          <div className={styles.member_email}>{email}</div>
        </div>
      </Row>
    </Link>
  );
}
