import { Table, TableProps } from 'antd';
import { ResponseDataType, getRequest } from 'api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface ActivityTrackingDataType {
  creator: { name: string; avatar?: string };
  content: string;
  type: number;
  createAt: string;
}

const columns: TableProps<ActivityTrackingDataType>['columns'] = [
  {
    title: 'Thời gian',
    dataIndex: 'createAt',
    key: 'createAt',
    width: '170px',
    render: (createAt: string) =>
      moment(createAt).format('HH:mm:ss DD/MM/YYYY'),
  },
  {
    title: 'Người tạo',
    dataIndex: 'creator',
    key: 'creator',
    width: '250px',
    render: (creator: { name: string; avatar?: string }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          style={{
            height: 30,
            width: 30,
            marginRight: 10,
            borderRadius: '50%',
          }}
          src={creator.avatar ? creator.avatar : '/Images/default-avatar.png'}
          alt=''
          onError={(e) => {
            (e.target as HTMLImageElement).src = '/Images/logo.png';
          }}
        />
        {creator.name}
      </div>
    ),
  },
  {
    title: 'Nội dung',
    dataIndex: 'content',
    key: 'content',
    // width: '200px',
  },
];

export const ActivityList = () => {
  const [actTrackingList, setActTrackingList] =
    useState<ActivityTrackingDataType[]>();

  useEffect(() => {
    const getActivityTrackingList = async () => {
      const response: ResponseDataType<ActivityTrackingDataType[]> =
        await getRequest('/tracking/get-activity-tracking');

      if (response.code) {
        toast.error(response.message);
      } else {
        setActTrackingList(response.data);
      }
    };

    getActivityTrackingList();
  }, []);

  return (
    <div>
      <Table columns={columns} dataSource={actTrackingList} />
    </div>
  );
};
