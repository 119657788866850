import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import styles from '../../organization.module.css';
import { Card } from 'antd';
import {
  CreateGroupPopup,
  DeleteGroupPopup,
  GroupItem,
  Popup,
  UpdateGroupPopup,
} from 'Component';
import { ResponseDataType, getRequest } from 'api';
import { toast } from 'react-toastify';

interface GroupInfoType {
  id: string;
  name: string;
  numberOfMembers: number;
  typeCode: string;
}

export default function GroupList() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [groupList, setGroupList] = useState<GroupInfoType[]>([]);
  // const [groupList, setGroupList] = useState<GroupInfoType[]>([]);
  const [groupInfoForUpdate, setGroupInfoForUpdate] = useState<GroupInfoType>();
  const [deleteGroupId, setDeleteGroupId] = useState<string>();
  const [isOpenCreatePopup, setIsOpenCreatePopup] = useState<boolean>(false);

  const GetGroupList = async () => {
    const response: ResponseDataType<GroupInfoType[]> = await getRequest(
      `/organization/${orgId}/group-list`
    );

    if (response.code) {
      toast.error(response.message);
      navigate('/customer-list');
    } else if (response.data) {
      setGroupList(response.data);
    }
  };

  useEffect(() => {
    GetGroupList();
    // eslint-disable-next-line
  }, [orgId, navigate]);

  return (
    <>
      <Card
        title='Danh sách group'
        className={styles.list_card}
        extra={
          <div
            style={{ borderRadius: '50%', cursor: 'pointer', lineHeight: 0 }}
            onClick={() => setIsOpenCreatePopup(true)}
          >
            <AddCircleOutlineOutlinedIcon style={{ fontSize: 30 }} />
          </div>
        }
      >
        {groupList.map((group, index) => (
          <GroupItem
            key={index}
            {...group}
            updateGroup={() => setGroupInfoForUpdate(group)}
            deleteGroup={() => setDeleteGroupId(group.id)}
          />
        ))}
      </Card>
      {groupInfoForUpdate && (
        <Popup close={() => setGroupInfoForUpdate(undefined)}>
          <UpdateGroupPopup
            {...groupInfoForUpdate}
            close={() => setGroupInfoForUpdate(undefined)}
            getGroupList={() => GetGroupList()}
          />
        </Popup>
      )}
      {deleteGroupId && (
        <Popup close={() => setDeleteGroupId(undefined)}>
          <DeleteGroupPopup
            groupId={deleteGroupId}
            close={() => setDeleteGroupId(undefined)}
            getGroupList={() => GetGroupList()}
          />
        </Popup>
      )}
      {isOpenCreatePopup && (
        <Popup close={() => setIsOpenCreatePopup(false)}>
          <CreateGroupPopup
            orgId={orgId as string}
            close={() => setIsOpenCreatePopup(false)}
            getGroupList={() => GetGroupList()}
          />
        </Popup>
      )}
    </>
  );
}
