import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Card } from 'antd';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import styles from '../../organization.module.css';
import { AddMemberPopup, MemberItem, Popup } from 'Component';
import { useNavigate, useParams } from 'react-router-dom';
import { ResponseDataType, getRequest } from 'api';
import { toast } from 'react-toastify';

interface MemberInfoType {
  cusOrgId: string;
  name: string;
  email: string;
  avatar?: string;
}

interface GetMemberResDataType {
  memberList: MemberInfoType[];
  quitMemberList: MemberInfoType[];
}

export default function MemberList() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [memberList, setMemberList] = useState<MemberInfoType[]>([]);
  const [quitMemberList, setQuitMemberList] = useState<MemberInfoType[]>([]);
  const [isOpenCreatePopup, setIsOpenCreatePopup] = useState<boolean>(false);

  const GetMemberList = async () => {
    const response: ResponseDataType<GetMemberResDataType> = await getRequest(
      `/organization/${orgId}/member-list`
    );

    if (response.code) {
      toast.error(response.message);
      navigate('/customer-list');
    } else if (response.data) {
      setMemberList(response.data.memberList);
      setQuitMemberList(response.data.quitMemberList);
    }
  };

  useEffect(() => {
    GetMemberList();
    // eslint-disable-next-line
  }, [orgId, navigate]);

  return (
    <Grid container spacing={5} marginTop={-2}>
      <Grid item>
        <Card
          title='Thành viên'
          className={styles.list_card}
          extra={
            <div
              style={{ borderRadius: '50%', cursor: 'pointer', lineHeight: 0 }}
              onClick={() => setIsOpenCreatePopup(true)}
            >
              <AddCircleOutlineOutlinedIcon style={{ fontSize: 30 }} />
            </div>
          }
        >
          {memberList.map((member, index) => (
            <MemberItem key={index} {...member} orgId={orgId} />
          ))}
        </Card>
      </Grid>
      <Grid item>
        <Card title='Đã nghỉ' className={styles.list_card}>
          {quitMemberList.map((member, index) => (
            <MemberItem key={index} {...member} orgId={orgId} />
          ))}
        </Card>
      </Grid>
      {isOpenCreatePopup && (
        <Popup close={() => setIsOpenCreatePopup(false)}>
          <AddMemberPopup
            orgId={orgId as string}
            close={() => setIsOpenCreatePopup(false)}
            getMemberList={() => GetMemberList()}
          />
        </Popup>
      )}
    </Grid>
  );
}
