import { popupStyles } from 'Component';
import { Button, Form, Input, Modal } from 'antd';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import StayPrimaryPortraitOutlinedIcon from '@mui/icons-material/StayPrimaryPortraitOutlined';

import { testImage } from 'utils';
import { ResponseDataType, postRequest } from 'api';
import { toast } from 'react-toastify';

interface FormValueType {
  email: string;
  name: string;
  phone: string;
  avatar?: string;
}

interface AddMemberPropType {
  orgId: string;
  close: () => void;
  getMemberList: () => void;
}

export default function AddMemberPopup(props: AddMemberPropType) {
  const { orgId, close, getMemberList } = props;
  const [form] = Form.useForm();

  const AddMember = (value: FormValueType) => {
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận thêm thành viên mới',
      async onOk() {
        const response: ResponseDataType<string> = await postRequest(
          '/organization/add-member',
          {
            organizationId: orgId,
            email: value.email,
            name: value.name,
            phone: value.phone,
            avatar: value.avatar,
          }
        );

        if (response.code === -1) {
          toast.error('Không có tài khoản ứng với email');
        } else if (response.code) {
          if (typeof response.message === 'object') {
            response.message.forEach((message: string) => toast.error(message));
          } else toast.error(response.message);
        } else {
          toast.success('Thêm thành viên mới thành công');
          close();
          getMemberList();
        }
      },
    });
  };

  return (
    <>
      <div className={popupStyles.popup_title}>Thêm thành viên</div>
      <Form form={form} onFinish={AddMember}>
        <div className={popupStyles.form}>
          <Form.Item
            name='email'
            rules={[
              { required: true, message: 'Cần nhập email!' },
              { type: 'email', message: 'Cần nhập email đúng định dạng!' },
            ]}
            style={{ margin: 0 }}
          >
            <Input
              prefix={
                <EmailOutlinedIcon
                  className={popupStyles.form_input_prefix_icon}
                />
              }
              placeholder='Email'
              className={popupStyles.form_input}
            />
          </Form.Item>
          <div className={popupStyles.line} />
          <Form.Item
            name='name'
            rules={[{ required: true, message: 'Cần nhập tên thành viên!' }]}
            style={{ margin: 0 }}
          >
            <Input
              prefix={
                <PermIdentityOutlinedIcon
                  className={popupStyles.form_input_prefix_icon}
                />
              }
              placeholder='Tên thành viên'
              className={popupStyles.form_input}
            />
          </Form.Item>
          <div className={popupStyles.line} />
          <Form.Item name='phone' style={{ margin: 0 }}>
            <Input
              prefix={
                <StayPrimaryPortraitOutlinedIcon
                  className={popupStyles.form_input_prefix_icon}
                />
              }
              placeholder='Số điện thoại'
              className={popupStyles.form_input}
            />
          </Form.Item>
          <div className={popupStyles.line} />
          <Form.Item
            name='avatar'
            rules={[
              () => ({
                async validator(_, value) {
                  if (!value) return Promise.resolve();
                  var checkFlag;
                  try {
                    checkFlag = await testImage(value, 3000);
                  } catch (error) {
                    checkFlag = false;
                  }
                  if (checkFlag) {
                    return Promise.resolve();
                  } else return Promise.reject('Link avatar lỗi!');
                },
              }),
            ]}
            style={{ margin: 0 }}
          >
            <Input
              prefix={
                <ImageOutlinedIcon
                  className={popupStyles.form_input_prefix_icon}
                />
              }
              placeholder='Avatar'
              className={popupStyles.form_input}
            />
          </Form.Item>
        </div>
        <div className={popupStyles.form_button_wrapper}>
          <Button
            type='primary'
            className={`${popupStyles.form_button} ${popupStyles.form_button_primary}`}
            htmlType='submit'
          >
            Thêm thành viên
          </Button>
          <Button
            type='primary'
            className={`${popupStyles.form_button} ${popupStyles.form_button_cancel}`}
            onClick={close}
          >
            Huỷ
          </Button>
        </div>
      </Form>
    </>
  );
}
