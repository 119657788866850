import { Button, Card, Col, Form, Input, Modal, Row, Select } from 'antd';
import { ResponseDataType, deleteRequest, getRequest, postRequest } from 'api';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import styles from '../../customer.module.css';

interface CustomerInfoType {
  email: string;

  status: number;
}

interface FormValueType {
  status?: number;
  password?: string;
  confirmPassword?: string;
}

const statusList = ['Chưa xác thực', 'Đã xác thực', 'Tạm khóa'];

export default function GeneralInfo() {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoType>();

  useEffect(() => {
    const GetCustomerInfo = async () => {
      const response: ResponseDataType<CustomerInfoType> = await getRequest(
        `/customer/customer-info/${customerId}`
      );

      if (response.code) {
        toast.error(response.message);
        navigate('/customer-list');
      } else {
        setCustomerInfo(response.data);
      }
    };

    GetCustomerInfo();
  }, [customerId, navigate]);

  const UpdateCustomerInfo = (value: FormValueType) => {
    if (
      (value.status === undefined || value.status === customerInfo?.status) &&
      (!value.password || value.password === '')
    ) {
      toast.error('Cần thay đổi ít nhất 1 thông tin để cập nhật');
      return;
    }

    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận cập nhật thông tin tài khoản?',
      async onOk() {
        const response: ResponseDataType<CustomerInfoType> = await postRequest(
          '/customer/update-customer-info',
          {
            customerId,
            status:
              value.status !== undefined &&
              value.status !== customerInfo?.status
                ? value.status
                : null,
            password:
              value.password && value.password !== '' ? value.password : null,
          }
        );

        if (response.code) {
          if (typeof response.message === 'object') {
            response.message.forEach((message: string) => toast.error(message));
          } else toast.error(response.message);
        } else {
          toast.success('Cập nhật thông tin tài khoản thành công');
          setCustomerInfo(response.data);
        }
      },
    });
  };

  const DeleteCustomer = () => {
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận xóa tài khoản?',
      async onOk() {
        const response: ResponseDataType<string> = await deleteRequest(
          `/customer/delete/${customerId}`
        );

        if (response.code) {
          toast.error(response.message);
        } else {
          toast.success('Xóa tài khoản thành công');
          navigate('/customer-list');
        }
      },
    });
  };

  return (
    <div>
      <Card title='Chi tiết tài khoản' className={styles.customer_info_card}>
        <Form form={form} onFinish={UpdateCustomerInfo}>
          <Row>
            <Col span={10}>
              <div
                className={styles.card_content_label}
                style={{ marginTop: 0 }}
              >
                <EmailOutlinedIcon />
                &nbsp;&nbsp;Email:
              </div>
            </Col>
            <Col
              span={14}
              style={{ width: '70%', color: '#009BE5', fontWeight: '600' }}
            >
              {customerInfo?.email}
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={10}>
              <div className={styles.card_content_label}>
                <NotificationsNoneOutlinedIcon />
                &nbsp;&nbsp;Trạng thái:
              </div>
            </Col>
            <Col span={14}>
              <Form.Item name='status'>
                <Select
                  style={{ marginBottom: 0 }}
                  allowClear
                  placeholder={
                    statusList[customerInfo?.status ? customerInfo.status : 1]
                  }
                  options={[
                    { value: 0, label: 'Chưa xác thực' },
                    { value: 1, label: 'Đã xác thực' },
                    { value: 2, label: 'Tạm khóa' },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div className={styles.card_content_label}>
                <RemoveRedEyeOutlinedIcon />
                &nbsp;&nbsp;Mật khẩu mới:
              </div>
            </Col>
            <Col span={14}>
              <Form.Item
                name='password'
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                    message: 'Mật khẩu không chứa kí tự đặc biệt và dấu cách',
                  },
                  () => ({
                    validator(_, value) {
                      if (value && value.length < 6) {
                        return Promise.reject('Mật khấu gồm ít nhất 6 kí tự');
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div className={styles.card_content_label}>
                <RemoveRedEyeOutlinedIcon />
                &nbsp;&nbsp;Xác nhận mật khẩu:
              </div>
            </Col>
            <Col span={14}>
              <Form.Item
                name='confirmPassword'
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && !getFieldValue('password')) {
                        return Promise.reject('Chưa nhập mật khẩu mới');
                      }

                      if (
                        getFieldValue('password') &&
                        value !== getFieldValue('password')
                      ) {
                        return Promise.reject('Mật khẩu xác nhận không khớp');
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Button type='primary' htmlType='submit'>
            Cập nhật
          </Button>
        </Form>
      </Card>
      <div className={styles.delete_customer_button} onClick={DeleteCustomer}>
        Xóa tài khoản
      </div>
    </div>
  );
}
