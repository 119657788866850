import axios from 'axios';

export interface ResponseDataType<T> {
  code: number;
  message: string | string[];
  data?: T;
}

export async function postRequest(url: string, body: any): Promise<any> {
  try {
    let response = await axios.post(
      process.env.REACT_APP_BASE_URL + '/api/cms' + url,
      body,
      generateRequestHeader()
    );
    return response.data;
  } catch (error: any) {
    // console.log(error);
    if (error?.response?.status === 401) {
      if (await refreshToken()) return;
      return await postRequest(url, body);
    }
    return error?.response?.data;
  }
}

export async function getRequest(url: string): Promise<any> {
  try {
    let response = await axios.get(
      process.env.REACT_APP_BASE_URL + '/api/cms' + url,
      generateRequestHeader()
    );
    return response.data;
  } catch (error: any) {
    // console.log(error);
    if (error?.response?.status === 401) {
      if (await refreshToken()) return;
      return await getRequest(url);
    }
    return error?.response?.data;
  }
}

export async function deleteRequest(url: string): Promise<any> {
  try {
    let response = await axios.delete(
      process.env.REACT_APP_BASE_URL + '/api/cms' + url,
      generateRequestHeader()
    );
    return response.data;
  } catch (error: any) {
    // console.log(error);
    if (error?.response?.status === 401) {
      if (await refreshToken()) return;
      return await deleteRequest(url);
    }
    return error?.response?.data;
  }
}

export function generateRequestHeader() {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  };
}

export async function refreshToken() {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + '/api/cms/auth/refresh-token',
      {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    localStorage.setItem('accessToken', await response.data.data.accessToken);
    localStorage.setItem('refreshToken', await response.data.data.refreshToken);
  } catch (error) {
    // console.log('refresh err:', error);
    localStorage.removeItem('email');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    window.location.href = '/login';
    return true;
  }
}
