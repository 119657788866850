import { Button, Form, Input, Modal } from 'antd';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import { toast } from 'react-toastify';

import { popupStyles } from 'Component';
import { ResponseDataType, postRequest } from 'api';

interface CreateDepartmentPopupPropType {
  orgId: string;
  close: () => void;
  getDepartmentList: () => void;
}

export default function CreateDepartmentPopup(
  props: CreateDepartmentPopupPropType
) {
  const { orgId, close, getDepartmentList } = props;
  const [form] = Form.useForm();

  const CreateDepartment = (value: { name: string; namecode: string }) => {
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận tạo phòng ban mới?',
      async onOk() {
        const response: ResponseDataType<{ departmentId: string }> =
          await postRequest('/organization/create-department', {
            organizationId: orgId,
            name: value.name,
            namecode: value.namecode,
          });

        if (response.code) {
          if (typeof response.message === 'object') {
            response.message.forEach((message: string) => toast.error(message));
          } else toast.error(response.message);
        } else {
          toast.success('Tạo phòng ban thành công');
          close();
          getDepartmentList();
        }
      },
    });
  };

  return (
    <>
      <div className={popupStyles.popup_title}>Thêm phòng ban</div>
      <Form form={form} onFinish={CreateDepartment}>
        <div className={popupStyles.form}>
          <Form.Item
            name='name'
            rules={[{ required: true, message: 'Cần nhập tên phòng ban!' }]}
            style={{ margin: 0 }}
          >
            <Input
              prefix={
                <DomainOutlinedIcon
                  className={popupStyles.form_input_prefix_icon}
                />
              }
              placeholder='Tên phòng ban'
              className={popupStyles.form_input}
            />
          </Form.Item>
          <div className={popupStyles.line} />
          <Form.Item
            name='namecode'
            rules={[{ required: true, message: 'Cần nhập mã phòng ban!' }]}
            style={{ margin: 0 }}
          >
            <Input
              prefix={
                <DomainOutlinedIcon
                  className={popupStyles.form_input_prefix_icon}
                />
              }
              placeholder='Mã phòng ban'
              className={popupStyles.form_input}
            />
          </Form.Item>
        </div>
        <div className={popupStyles.form_button_wrapper}>
          <Button
            type='primary'
            className={`${popupStyles.form_button} ${popupStyles.form_button_primary}`}
            htmlType='submit'
          >
            Thêm
          </Button>
          <Button
            type='primary'
            className={`${popupStyles.form_button} ${popupStyles.form_button_cancel}`}
            onClick={close}
          >
            Huỷ
          </Button>
        </div>
      </Form>
    </>
  );
}
