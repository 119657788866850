import { Button, Card, Col, Form, Input, Modal, Row, Select } from 'antd';
import { toast } from 'react-toastify';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import styles from '../customer.module.css';
import { ResponseDataType, postRequest } from 'api';

interface CreateCustomerResponseType {
  email: string;
  status: number;
  id: string;
}

interface FormValueType {
  email: string;
  status: number;
  password?: string;
  confirmPassword?: string;
}
export default function CreateCustomer() {
  const [form] = Form.useForm();

  const HandleCreateCustomer = (value: FormValueType) => {
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận tạo tài khoản mới',
      async onOk() {
        const response: ResponseDataType<CreateCustomerResponseType> =
          await postRequest('/customer/create-customer', {
            email: value.email,
            isActivated: !!value.status,
            password:
              value.password && value.password !== ''
                ? value.password
                : undefined,
          });

        if (response.code) {
          if (typeof response.message === 'object') {
            response.message.forEach((message: string) => toast.error(message));
          } else toast.error(response.message);
        } else {
          toast.success('Tạo tài khoản mới thành công!');
        }
      },
    });
  };

  return (
    <div>
      <Card title='Chi tiết tài khoản' className={styles.customer_info_card}>
        <Form form={form} onFinish={HandleCreateCustomer}>
          <Row>
            <Col span={8}>
              <div className={styles.card_content_label}>
                <EmailOutlinedIcon />
                &nbsp;&nbsp;Email:
              </div>
            </Col>
            <Col span={16}>
              <Form.Item
                name='email'
                rules={[
                  { required: true, message: 'Cần nhập email!' },
                  { type: 'email', message: 'Cần nhập email đúng định dạng!' },
                ]}
              >
                <Input placeholder='Email' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className={styles.card_content_label}>
                <NotificationsNoneOutlinedIcon />
                &nbsp;&nbsp;Trạng thái:
              </div>
            </Col>
            <Col span={16}>
              <Form.Item
                name='status'
                rules={[{ required: true, message: 'Cần chọn trạng thái' }]}
              >
                <Select
                  style={{ marginBottom: 0 }}
                  allowClear
                  placeholder='Trạng thái'
                  options={[
                    { value: 0, label: 'Chưa xác thực' },
                    { value: 1, label: 'Đã xác thực' },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className={styles.card_content_label}>
                <RemoveRedEyeOutlinedIcon />
                &nbsp;&nbsp;Mật khẩu:
              </div>
            </Col>
            <Col span={16}>
              <Form.Item
                name='password'
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                    message: 'Mật khẩu không chứa kí tự đặc biệt và dấu cách',
                  },
                  () => ({
                    validator(_, value) {
                      if (value && value.length < 6) {
                        return Promise.reject('Mật khấu gồm ít nhất 6 kí tự');
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password placeholder='Mật khẩu' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className={styles.card_content_label}>
                <RemoveRedEyeOutlinedIcon />
                &nbsp;&nbsp;Xác nhận:
              </div>
            </Col>
            <Col span={16}>
              <Form.Item
                name='confirmPassword'
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && !getFieldValue('password')) {
                        return Promise.reject('Chưa nhập mật khẩu mới');
                      }

                      if (
                        getFieldValue('password') &&
                        value !== getFieldValue('password')
                      ) {
                        return Promise.reject('Mật khẩu xác nhận không khớp');
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password placeholder='Xác nhận mật khẩu' />
              </Form.Item>
            </Col>
          </Row>
          <Button type='primary' htmlType='submit'>
            Thêm tài khoản
          </Button>
        </Form>
      </Card>
    </div>
  );
}
