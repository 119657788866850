import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { Button } from 'antd';
import { ResponseDataType, deleteRequest } from 'api';

import { popupStyles } from 'Component';
import { toast } from 'react-toastify';

interface DeleteGroupPropsType {
  groupId: string;
  close: () => void;
  getGroupList: () => void;
}

export default function DeleteGroupPopup(props: DeleteGroupPropsType) {
  const { groupId, close, getGroupList } = props;

  const DeleteGroup = async () => {
    const response: ResponseDataType<string> = await deleteRequest(
      `/organization/delete-group/${groupId}`
    );

    if (response.code) {
      toast.error(response.message);
      close();
    } else {
      toast.success('Xóa phòng ban thành công');
      close();
      getGroupList();
    }
  };

  return (
    <>
      <div style={{ color: '#FF0303' }} className={popupStyles.popup_title}>
        Xóa group
      </div>
      <ErrorOutlinedIcon sx={{ color: '#FF0303', fontSize: 64 }} />
      <div
        style={{ color: '#FF0303', fontWeight: 700, margin: '20px 0 10px 0' }}
      >
        BẠN CÓ CHẮC CHẮN MUỐN
      </div>
      <div style={{ color: '#FF0303', fontWeight: 700, fontSize: 20 }}>
        XÓA GROUP
      </div>
      <div className={popupStyles.form_button_wrapper}>
        <Button
          type='primary'
          danger
          className={`${popupStyles.form_button}`}
          onClick={DeleteGroup}
        >
          Xóa group
        </Button>
        <Button
          type='primary'
          className={`${popupStyles.form_button} ${popupStyles.form_button_cancel}`}
          onClick={close}
        >
          Huỷ
        </Button>
      </div>
    </>
  );
}
