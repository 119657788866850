import { useEffect, useState } from 'react';

import styles from '../customer.module.css';
import { useAppContext } from 'Context/AppContext';
import { Table, TableProps } from 'antd';
import { Link } from 'react-router-dom';
import { getRequest, ResponseDataType } from 'api';

interface CustomerDataType {
  id: string;
  email: string;
  organization: string[];
  organizationAdmin: string[];
  status: number;
}

interface CustomerListType {
  activatedList?: CustomerDataType[];
  notActivatedList?: CustomerDataType[];
}

const columns: TableProps<CustomerDataType>['columns'] = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: '300px',
  },
  {
    title: 'Danh sách tổ chức tham gia',
    dataIndex: 'organization',
    key: 'organization',
    render: (orgList: string[]) => (
      <ul>
        {orgList.map((org, index) => (
          <li key={index}>{org}</li>
        ))}
      </ul>
    ),
  },
  {
    title: 'Danh sách tổ chức quản lý',
    dataIndex: 'organizationAdmin',
    key: 'organization-list',
    render: (orgList: string[]) => (
      <ul>
        {orgList.map((org, index) => (
          <li key={index}>{org}</li>
        ))}
      </ul>
    ),
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    width: '140px',
    render: (status) => (status ? 'Đã xác thực' : 'Chưa xác thực'),
  },
  {
    title: 'Hành động',
    dataIndex: 'id',
    key: 'action',
    width: '140px',
    render: (id) => (
      <Link to={`/customer-list/customer-info/${id}`}>Chi tiết</Link>
    ),
  },
];

export default function CustomerList() {
  const { tabNumber } = useAppContext();
  const [customerList, setCustomerList] = useState<CustomerDataType[]>();

  useEffect(() => {
    const GetCustomerList = async () => {
      const response: ResponseDataType<CustomerListType> = await getRequest(
        tabNumber === 0
          ? '/customer/activated-list'
          : '/customer/not-activated-list'
      );

      setCustomerList(
        tabNumber === 0
          ? response.data?.activatedList
          : response.data?.notActivatedList
      );
    };

    GetCustomerList();
  }, [tabNumber]);

  return (
    <div className={styles.customer_list_wrapper}>
      <Table columns={columns} dataSource={customerList} />
    </div>
  );
}
