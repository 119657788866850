import { Button, Form, Input, Modal } from 'antd';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { toast } from 'react-toastify';

import { popupStyles } from 'Component';
import { testImage } from 'utils';
import { ResponseDataType, postRequest } from 'api';

interface CreateOrganizationPropType {
  customerId: string;
  close: () => void;
  getOrgList: () => void;
}

export default function CreateOrganizationPopup(
  props: CreateOrganizationPropType
) {
  const [form] = Form.useForm();
  const { close, customerId, getOrgList } = props;

  const CreateOrganization = (value: { name: string; logo: string }) => {
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận tạo tổ chức mới?',
      async onOk() {
        const response: ResponseDataType<string> = await postRequest(
          '/organization/create',
          {
            name: value.name,
            logo: value.logo,
            ownerId: customerId,
          }
        );

        if (response.code) {
          if (typeof response.message === 'object') {
            response.message.forEach((message: string) => toast.error(message));
          } else toast.error(response.message);
        } else {
          toast.success('Tạo tổ chức thành công');
          close();
          getOrgList();
        }
      },
    });
  };

  return (
    <>
      <div className={popupStyles.popup_title}>Thêm tổ chức</div>
      <Form form={form} onFinish={CreateOrganization}>
        <div className={popupStyles.form}>
          <Form.Item
            name='name'
            rules={[{ required: true, message: 'Cần nhập tên tổ chức!' }]}
            style={{ margin: 0 }}
          >
            <Input
              prefix={
                <DomainOutlinedIcon
                  className={popupStyles.form_input_prefix_icon}
                />
              }
              placeholder='Tên tổ chức'
              className={popupStyles.form_input}
            />
          </Form.Item>
          <div className={popupStyles.line} />
          <Form.Item
            name='logo'
            rules={[
              () => ({
                async validator(_, value) {
                  if (!value) return Promise.resolve();
                  var checkFlag;
                  try {
                    checkFlag = await testImage(value, 2000);
                  } catch (error) {
                    checkFlag = false;
                  }
                  if (checkFlag) {
                    return Promise.resolve();
                  } else return Promise.reject('Link logo lỗi!');
                },
              }),
            ]}
            style={{ margin: 0 }}
          >
            <Input
              prefix={
                <ImageOutlinedIcon
                  className={popupStyles.form_input_prefix_icon}
                />
              }
              placeholder='Logo'
              className={popupStyles.form_input}
            />
          </Form.Item>
        </div>
        <div className={popupStyles.form_button_wrapper}>
          <Button
            type='primary'
            className={`${popupStyles.form_button} ${popupStyles.form_button_primary}`}
            htmlType='submit'
          >
            Thêm tổ chức
          </Button>
          <Button
            type='primary'
            className={`${popupStyles.form_button} ${popupStyles.form_button_cancel}`}
            onClick={close}
          >
            Huỷ
          </Button>
        </div>
      </Form>
    </>
  );
}
