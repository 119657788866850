import { Button, Form, Input, Modal } from 'antd';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import { toast } from 'react-toastify';

import { popupStyles } from 'Component';
import { ResponseDataType, postRequest } from 'api';

interface CreateGroupPopupPropType {
  orgId: string;
  close: () => void;
  getGroupList: () => void;
}

export default function CreateGroupPopup(props: CreateGroupPopupPropType) {
  const { orgId, close, getGroupList } = props;
  const [form] = Form.useForm();

  const CreateGroup = (value: { name: string; typeCode: string }) => {
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận tạo group mới?',
      async onOk() {
        const response: ResponseDataType<string> = await postRequest(
          '/organization/create-group',
          {
            organizationId: orgId,
            name: value.name,
            typeCode: value.typeCode,
          }
        );

        if (response.code) {
          if (typeof response.message === 'object') {
            response.message.forEach((message: string) => toast.error(message));
          } else toast.error(response.message);
        } else {
          toast.success('Tạo group thành công');
          close();
          getGroupList();
        }
      },
    });
  };

  return (
    <>
      <div className={popupStyles.popup_title}>Thêm group</div>
      <Form form={form} onFinish={CreateGroup}>
        <div className={popupStyles.form}>
          <Form.Item
            name='name'
            rules={[{ required: true, message: 'Cần nhập tên group!' }]}
            style={{ margin: 0 }}
          >
            <Input
              prefix={
                <DomainOutlinedIcon
                  className={popupStyles.form_input_prefix_icon}
                />
              }
              placeholder='Tên group'
              className={popupStyles.form_input}
            />
          </Form.Item>
          <Form.Item
            name='typeCode'
            rules={[
              { required: true, message: 'Cần nhập mã phân loại group!' },
            ]}
            style={{ margin: 0 }}
          >
            <Input
              prefix={
                <DomainOutlinedIcon
                  className={popupStyles.form_input_prefix_icon}
                />
              }
              placeholder='Mã phân loại'
              className={popupStyles.form_input}
            />
          </Form.Item>
        </div>
        <div className={popupStyles.form_button_wrapper}>
          <Button
            type='primary'
            className={`${popupStyles.form_button} ${popupStyles.form_button_primary}`}
            htmlType='submit'
          >
            Thêm
          </Button>
          <Button
            type='primary'
            className={`${popupStyles.form_button} ${popupStyles.form_button_cancel}`}
            onClick={close}
          >
            Huỷ
          </Button>
        </div>
      </Form>
    </>
  );
}
