import { useAppContext } from 'Context/AppContext';
import GeneralInfo from './Tabs/GeneralInfo';
import DepartmentList from './Tabs/DepartmentList';
import GroupList from './Tabs/GroupList';
import MemberList from './Tabs/MemberList';

export default function OrganizationInfo() {
  const { tabNumber } = useAppContext();

  if (tabNumber === 0) return <GeneralInfo />;
  else if (tabNumber === 1) return <DepartmentList />;
  else if (tabNumber === 2) return <GroupList />;
  else if (tabNumber === 3) return <MemberList />;
  else return <></>;
}
