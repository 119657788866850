import { useAppContext } from 'Context/AppContext';
import GeneralInfo from './Tabs/GeneralInfo';
import OrganizationList from './Tabs/OrganizationList';

export default function CustomerInfo() {
  const { tabNumber } = useAppContext();

  if (tabNumber === 0) return <GeneralInfo />;
  else if (tabNumber === 1) return <OrganizationList />;
  else return <></>;
}
