import {
  CustomerInfo,
  CustomerList,
  Home,
  CreateCustomer,
  OrganizationList,
  OrganizationInfo,
  MemberInfo,
  ActivityList,
} from 'Pages';

export const PUBLIC_ROUTER = [
  {
    key: 'login',
    path: '/login',
  },
];

export const ADMIN_ROUTER = [
  {
    key: 'home',
    path: '/',
    element: <Home />,
  },
  {
    key: 'add-customer',
    path: '/add-customer',
    navKey: 'ac',
    element: <CreateCustomer />,
  },
  {
    key: 'customer-list',
    path: '/customer-list',
    navKey: 'cl',
    element: <CustomerList />,
  },
  {
    key: 'customer-info',
    path: '/customer-list/customer-info/:customerId',
    navKey: 'cl',
    subNavKey: 'ci',
    element: <CustomerInfo />,
  },
  {
    key: 'organization-list',
    path: '/organization-list',
    navKey: 'ol',
    element: <OrganizationList />,
  },
  {
    key: 'organization-info',
    path: '/organization-list/organization-info/:orgId',
    navKey: 'ol',
    subNavKey: 'oi',
    element: <OrganizationInfo />,
  },
  {
    key: 'organization-info',
    path: '/organization-list/organization-info/:orgId/member-info/:memberId',
    navKey: 'ol',
    subNavKey: 'oi',
    subSubNavKey: 'mi',
    element: <MemberInfo />,
  },
  {
    key: 'activity-list',
    path: '/activity-list',
    navKey: 'al',
    element: <ActivityList />,
  },
];

export const ADMIN_NAV_ITEM_PATH = [
  'add-customer',
  'customer-list',
  'organization-list',
];

export const PATH_INFO = {
  '/': { title: 'Trang chủ' },
  '/add-customer': { title: 'Thêm tài khoản' },
  '/customer-list': {
    title: 'Danh sách tài khoản',
    tabList: ['Đã xác thực', 'Chưa xác thực'],
  },
  '/customer-list/customer-info': {
    title: 'Chi tiết tài khoản',
    tabList: ['Thông tin chung', 'Danh sách tổ chức'],
  },
  '/organization-list': { title: 'Danh sách tổ chức' },
  '/organization-list/organization-info': {
    title: 'Chi tiết tổ chức',
    tabList: [
      'Tổng quan',
      'Danh sách phòng ban',
      'Danh sách group',
      'Danh sách thành viên',
    ],
  },
  '/organization-list/organization-info/member-info': {
    title: 'Chi tiết thành viên tổ chức',
    tabList: ['Thông tin chung', 'Phòng ban', 'Group', 'Hoạt động'],
  },
  '/activity-list': { title: 'Danh sách hoạt động' },
};
