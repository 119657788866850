import Paper from '@mui/material/Paper';
import { Link, useParams } from 'react-router-dom';

import styles from './layout.module.css';

export interface ContentPropType {
  children?: React.ReactElement;
  navKey?: string;
  subNavKey?: string;
  subSubNavKey?: string;
}

export default function Content(props: ContentPropType) {
  const { children, navKey, subNavKey, subSubNavKey } = props;

  return (
    <Paper
      sx={{
        margin: 'auto',
        borderRadius: 0,
        overflow: 'hidden',
        backgroundColor: 'inherit',
        boxShadow: 'none',
      }}
    >
      <NavigationTree
        navKey={navKey}
        subNavKey={subNavKey}
        subSubNavKey={subSubNavKey}
      />
      <div style={{ padding: '0px 25px' }}>{children}</div>
    </Paper>
  );
}

interface NavigationTreeProps {
  navKey?: string;
  subNavKey?: string;
  subSubNavKey?: string;
}

const navList = {
  ac: { name: 'Thêm tài khoản', path: '/add-customer' },
  cl: { name: 'Danh sách tài khoản', path: '/customer-list' },
  ol: { name: 'Danh sách tổ chức', path: '/organization-list' },
  al: { name: 'Danh sách hoạt động', path: '/activity-list' },
};

const subNavList = {
  ci: { name: 'Chi tiết tài khoản', path: '/customer-list/customer-info' },
  oi: {
    name: 'Chi tiết tổ chức',
    path: '/organization-list/organization-info',
  },
};

const NavigationTree: React.FC<NavigationTreeProps> = ({
  navKey,
  subNavKey,
  subSubNavKey,
}) => {
  const { orgId } = useParams();

  return (
    <div className={styles.navigation_tree}>
      <Link
        className={`${styles.link} ${
          navKey ? styles.previous_nav : styles.current_nav
        }`}
        to='/'
      >
        Home
      </Link>
      &nbsp;&nbsp;
      {navKey && (
        <>
          <span>{'>>'}</span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Link
            className={`${styles.link} ${
              subNavKey ? styles.previous_nav : styles.current_nav
            }`}
            to={navList[navKey as keyof typeof navList]?.path}
          >
            {navList[navKey as keyof typeof navList]?.name}
          </Link>
          &nbsp;&nbsp;
        </>
      )}
      {subNavKey && (
        <>
          <span>{'>>'}</span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {subSubNavKey ? (
            <Link
              className={`${styles.link} ${styles.previous_nav}`}
              to={`${
                subNavList[subNavKey as keyof typeof subNavList]?.path
              }/${orgId}`}
            >
              {subNavList[subNavKey as keyof typeof subNavList]?.name}
            </Link>
          ) : (
            <div className={`${styles.link} ${styles.current_nav}`}>
              {subNavList[subNavKey as keyof typeof subNavList]?.name}
            </div>
          )}
          &nbsp;&nbsp;
        </>
      )}
      {subSubNavKey && (
        <>
          <span>{'>>'}</span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <div className={`${styles.link} ${styles.current_nav}`}>
            Chi tiết thành viên tổ chức
          </div>
        </>
      )}
    </div>
  );
};
