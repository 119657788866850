import { Grid } from '@mui/material';
import { Card } from 'antd';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import styles from '../../customer.module.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ResponseDataType, getRequest } from 'api';
import { toast } from 'react-toastify';
import {
  CreateOrganizationPopup,
  DeleteOrganizationPopup,
  OrganizationItem,
  Popup,
  UpdateOrganizationPopup,
} from 'Component';

interface OrganizationInfoType {
  id: string;
  name: string;
  logo: string;
  number_of_members: number;
}

interface OrganizationListType {
  organizationList: OrganizationInfoType[];
  organizationAdmin: OrganizationInfoType[];
}

export default function OrganizationList() {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [organizationList, setOrganizationList] =
    useState<OrganizationListType>({
      organizationList: [],
      organizationAdmin: [],
    });
  const [orgInUpdatePopup, setOrgInUpdatePopup] =
    useState<OrganizationInfoType>();
  const [orgInDeletePopup, setOrgInDeletePopup] = useState<string>();
  const [isOpenCreatePopup, setIsOpenCreatePopup] = useState<boolean>(false);

  const GetOrgList = async () => {
    const response: ResponseDataType<OrganizationListType> = await getRequest(
      `/customer/organization-list/${customerId}`
    );

    if (response.code) {
      toast.error(response.message);
      navigate('/customer-list');
    } else if (response.data) {
      setOrganizationList(response.data);
    }
  };

  useEffect(() => {
    GetOrgList();
    // eslint-disable-next-line
  }, [customerId, navigate]);

  return (
    <Grid container spacing={5} marginTop={-2}>
      <Grid item>
        <Card
          title='Quản trị'
          className={styles.org_list_card}
          extra={
            <div
              style={{ borderRadius: '50%', cursor: 'pointer', lineHeight: 0 }}
              onClick={() => setIsOpenCreatePopup(true)}
            >
              <AddCircleOutlineOutlinedIcon style={{ fontSize: 30 }} />
            </div>
          }
        >
          {organizationList.organizationAdmin.map((org, index) => (
            <OrganizationItem
              key={index}
              {...org}
              isAdmin={true}
              updateOrganization={() => setOrgInUpdatePopup(org)}
              deleteOrganization={() => setOrgInDeletePopup(org.id)}
            />
          ))}
        </Card>
      </Grid>
      <Grid item>
        <Card title='Tham gia' className={styles.org_list_card}>
          {organizationList.organizationList.map((org, index) => (
            <OrganizationItem
              key={index}
              {...org}
              isAdmin={false}
              updateOrganization={() => setOrgInUpdatePopup(org)}
            />
          ))}
        </Card>
      </Grid>
      {orgInUpdatePopup && (
        <Popup close={() => setOrgInUpdatePopup(undefined)}>
          <UpdateOrganizationPopup
            {...orgInUpdatePopup}
            close={() => setOrgInUpdatePopup(undefined)}
            getOrgList={() => GetOrgList()}
          />
        </Popup>
      )}
      {orgInDeletePopup && (
        <Popup close={() => setOrgInDeletePopup(undefined)}>
          <DeleteOrganizationPopup
            organizationId={orgInDeletePopup}
            close={() => setOrgInDeletePopup(undefined)}
            getOrgList={() => GetOrgList()}
          />
        </Popup>
      )}
      {isOpenCreatePopup && (
        <Popup close={() => setIsOpenCreatePopup(false)}>
          <CreateOrganizationPopup
            customerId={customerId as string}
            close={() => setIsOpenCreatePopup(false)}
            getOrgList={() => GetOrgList()}
          />
        </Popup>
      )}
    </Grid>
  );
}
