import { Button, Card, Col, Form, Input, Modal, Row } from 'antd';

import styles from '../../organization.module.css';
import { testImage } from 'utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ResponseDataType, deleteRequest, getRequest, postRequest } from 'api';
import { toast } from 'react-toastify';

interface OrganizationInfoType {
  owner: string;
  name: string;
  logo?: string;
  number_of_members: number;
  number_of_invited_members: number;
  number_of_quit_members: number;
}

interface UpdateOrganizationResponseType {
  name: string;
  logo?: string;
}

export default function GeneralInfo() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [organizationInfo, setOrganizationInfo] =
    useState<OrganizationInfoType>();

  useEffect(() => {
    const GetOrganization = async () => {
      const response: ResponseDataType<OrganizationInfoType> = await getRequest(
        `/organization/organization-info/${orgId}`
      );

      if (response.code) {
        toast.error(response.message);
        navigate('/organization-list');
      } else {
        setOrganizationInfo(response.data);
        form.setFieldsValue({
          name: response.data?.name,
          logo: response.data?.logo,
        });
      }
    };

    GetOrganization();
  }, [orgId, navigate, form]);

  const UpdateOrganizationInfo = (value: { name: string; logo?: string }) => {
    if (
      value.name === organizationInfo?.name &&
      value.logo === organizationInfo?.logo
    ) {
      toast.error('Cần thay đổi ít nhất 1 thông tin để cập nhật');
      return;
    }

    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận cập nhật thông tin tổ chức?',
      async onOk() {
        const response: ResponseDataType<UpdateOrganizationResponseType> =
          await postRequest('/organization/update', {
            organizationId: orgId,
            name: value.name,
            logo: value.logo,
          });

        if (response.code) {
          if (typeof response.message === 'object') {
            response.message.forEach((message: string) => toast.error(message));
          } else toast.error(response.message);
        } else {
          toast.success('Cập nhật thông tin tổ chức thành công');
          setOrganizationInfo({
            ...(organizationInfo as OrganizationInfoType),
            name: response.data?.name as string,
            logo: response.data?.logo,
          });
        }
      },
    });
  };

  const DeleteOrganization = () => {
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận xóa tổ chức?',
      async onOk() {
        const response: ResponseDataType<string> = await deleteRequest(
          `/organization/delete/${orgId}`
        );

        if (response.code) {
          toast.error(response.message);
        } else {
          toast.success('Xóa tổ chức thành công');
          navigate('/organization-list');
        }
      },
    });
  };

  return (
    <div>
      <Card
        title='Chi tiết tài khoản'
        className={styles.organization_info_card}
      >
        <Form form={form} onFinish={UpdateOrganizationInfo}>
          <Row style={{ marginBottom: 20 }}>
            <Col span={10}>
              <div
                className={styles.card_content_label}
                style={{ marginTop: 0 }}
              >
                &nbsp;&nbsp;Quản trị viên:
              </div>
            </Col>
            <Col
              span={14}
              style={{ width: '70%', color: '#009BE5', fontWeight: '600' }}
            >
              {organizationInfo?.owner}
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col span={10}>
              <div
                className={styles.card_content_label}
                style={{ marginTop: 0 }}
              >
                &nbsp;&nbsp;Thành viên:
              </div>
            </Col>
            <Col
              span={14}
              style={{ width: '70%', color: '#009BE5', fontWeight: '600' }}
            >
              {organizationInfo?.number_of_members
                ? organizationInfo.number_of_members < 10
                  ? `0${organizationInfo.number_of_members}`
                  : organizationInfo.number_of_members
                : '00'}
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col span={10}>
              <div
                className={styles.card_content_label}
                style={{ marginTop: 0 }}
              >
                &nbsp;&nbsp;Đang mời:
              </div>
            </Col>
            <Col
              span={14}
              style={{ width: '70%', color: '#009BE5', fontWeight: '600' }}
            >
              {organizationInfo?.number_of_invited_members
                ? organizationInfo.number_of_invited_members < 10
                  ? `0${organizationInfo.number_of_invited_members}`
                  : organizationInfo.number_of_invited_members
                : '00'}
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col span={10}>
              <div
                className={styles.card_content_label}
                style={{ marginTop: 0 }}
              >
                &nbsp;&nbsp;Đã nghỉ:
              </div>
            </Col>
            <Col
              span={14}
              style={{ width: '70%', color: '#009BE5', fontWeight: '600' }}
            >
              {organizationInfo?.number_of_quit_members
                ? organizationInfo.number_of_quit_members < 10
                  ? `0${organizationInfo.number_of_quit_members}`
                  : organizationInfo.number_of_quit_members
                : '00'}
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div className={styles.card_content_label}>
                &nbsp;&nbsp;Tên tổ chức:
              </div>
            </Col>
            <Col span={14}>
              <Form.Item
                name='name'
                rules={[{ required: true, message: 'Cần nhập tên tổ chức!' }]}
              >
                <Input placeholder={organizationInfo?.name} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div className={styles.card_content_label}>&nbsp;&nbsp;Logo:</div>
            </Col>
            <Col span={14}>
              <Form.Item
                name='logo'
                rules={[
                  () => ({
                    async validator(_, value) {
                      if (!value) return Promise.resolve();
                      var checkFlag;
                      try {
                        checkFlag = await testImage(value, 2000);
                      } catch (error) {
                        checkFlag = false;
                      }
                      if (checkFlag) {
                        return Promise.resolve();
                      } else return Promise.reject('Link avatar lỗi!');
                    },
                  }),
                ]}
              >
                <Input placeholder={organizationInfo?.logo} />
              </Form.Item>
            </Col>
          </Row>
          <Button type='primary' htmlType='submit'>
            Cập nhật
          </Button>
        </Form>
      </Card>
      <div
        className={styles.delete_organization_button}
        onClick={DeleteOrganization}
      >
        Xóa tổ chức
      </div>
    </div>
  );
}
