import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CircularProgress, Grid } from '@mui/material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';

import styles from './home.module.css';
import { ResponseDataType, getRequest } from 'api';
import { useAppContext } from 'Context/AppContext';

const card_sx = {
  color: '#141D49',
  padding: '10px',
  width: '250px',
  cursor: 'pointer',
};

const circular_progress_sx = {
  color: '#141D49',
};

interface GeneralInfoDataType {
  customerActivated: number;
  customerNotActivated: number;
  organization: number;
}

export default function Home() {
  const { setResetTab, setTabNumber } = useAppContext();
  const [generalInfo, setGeneralInfo] = useState<GeneralInfoDataType>();

  useEffect(() => {
    const GetGeneralInfo = async () => {
      const response: ResponseDataType<GeneralInfoDataType> = await getRequest(
        '/general-information'
      );

      setGeneralInfo(response.data);
    };

    GetGeneralInfo();
  }, []);

  return (
    <Grid container spacing={5}>
      <Grid item>
        <Link
          onClick={() => {
            setTabNumber?.(1);
            setResetTab?.(false);
          }}
          style={{ textDecoration: 'none' }}
          to='/customer-list'
        >
          <Card sx={card_sx}>
            <p className={styles.card_title}>Đăng ký</p>
            <p className={styles.card_number}>
              {generalInfo?.customerNotActivated ?? (
                <CircularProgress sx={circular_progress_sx} />
              )}
            </p>
            <Grid container justifyContent='flex-end'>
              <PeopleAltOutlinedIcon />
            </Grid>
          </Card>
        </Link>
      </Grid>
      <Grid item>
        <Link style={{ textDecoration: 'none' }} to='/customer-list'>
          <Card sx={card_sx}>
            <p className={styles.card_title}>Tài khoản</p>
            <p className={styles.card_number}>
              {generalInfo?.customerActivated ?? (
                <CircularProgress sx={circular_progress_sx} />
              )}
            </p>
            <Grid container justifyContent='flex-end'>
              <PeopleAltOutlinedIcon />
            </Grid>
          </Card>
        </Link>
      </Grid>
      <Grid item>
        <Link style={{ textDecoration: 'none' }} to='/organization-list'>
          <Card sx={card_sx}>
            <p className={styles.card_title}>Tổ chức</p>
            <p className={styles.card_number}>
              {generalInfo?.organization ?? (
                <CircularProgress sx={circular_progress_sx} />
              )}
            </p>
            <Grid container justifyContent='flex-end'>
              <DomainOutlinedIcon />
            </Grid>
          </Card>
        </Link>
      </Grid>
    </Grid>
  );
}
