import { useEffect, useState } from 'react';
import { Card } from 'antd';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import styles from '../../organization.module.css';
import {
  CreateDepartmentPopup,
  DeleteDepartmentPopup,
  DepartmentItem,
  Popup,
  UpdateDepartmentPopup,
} from 'Component';
import { ResponseDataType, getRequest } from 'api';

interface DepartmentInfoType {
  id: string;
  name: string;
  numberOfMembers: number;
  managerName?: string;
  managerId?: string;
  namecode?: string;
}

export default function DepartmentList() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [departmentList, setDepartmentList] = useState<DepartmentInfoType[]>(
    []
  );
  const [departmentInfoForUpdate, setDepartmentInfoForUpdate] =
    useState<DepartmentInfoType>();
  const [deleteDepartmentId, setDeleteDepartmentId] = useState<string>();
  const [isOpenCreatePopup, setIsOpenCreatePopup] = useState<boolean>(false);

  const GetDepartmentList = async () => {
    const response: ResponseDataType<DepartmentInfoType[]> = await getRequest(
      `/organization/${orgId}/department-list`
    );

    if (response.code) {
      toast.error(response.message);
      navigate('/customer-list');
    } else if (response.data) {
      setDepartmentList(response.data);
    }
  };

  useEffect(() => {
    GetDepartmentList();
    // eslint-disable-next-line
  }, [orgId, navigate]);

  return (
    <>
      <Card
        title='Danh sách phòng ban'
        className={styles.list_card}
        extra={
          <div
            style={{ borderRadius: '50%', cursor: 'pointer', lineHeight: 0 }}
            onClick={() => setIsOpenCreatePopup(true)}
          >
            <AddCircleOutlineOutlinedIcon style={{ fontSize: 30 }} />
          </div>
        }
      >
        {departmentList.map((department, index) => (
          <DepartmentItem
            key={index}
            {...department}
            updateDepartment={() => setDepartmentInfoForUpdate(department)}
            deleteDepartment={() => setDeleteDepartmentId(department.id)}
            orgId={orgId as string}
          />
        ))}
      </Card>
      {departmentInfoForUpdate && (
        <Popup close={() => setDepartmentInfoForUpdate(undefined)}>
          <UpdateDepartmentPopup
            {...departmentInfoForUpdate}
            close={() => setDepartmentInfoForUpdate(undefined)}
            getDepartmentList={() => GetDepartmentList()}
          />
        </Popup>
      )}
      {deleteDepartmentId && (
        <Popup close={() => setDeleteDepartmentId(undefined)}>
          <DeleteDepartmentPopup
            departmentId={deleteDepartmentId}
            close={() => setDeleteDepartmentId(undefined)}
            getDepartmentList={() => GetDepartmentList()}
          />
        </Popup>
      )}
      {isOpenCreatePopup && (
        <Popup close={() => setIsOpenCreatePopup(false)}>
          <CreateDepartmentPopup
            orgId={orgId as string}
            close={() => setIsOpenCreatePopup(false)}
            getDepartmentList={() => GetDepartmentList()}
          />
        </Popup>
      )}
    </>
  );
}
