import { Row } from 'antd';

import styles from '../customer.module.css';
import { Link } from 'react-router-dom';

interface OrganizationInfoType {
  id: string;
  name: string;
  logo: string;
  number_of_members: number;
}

interface OrganizationItemPropType extends OrganizationInfoType {
  isAdmin: boolean;
  updateOrganization: () => void;
  deleteOrganization?: () => void;
}

export default function OrganizationItem(props: OrganizationItemPropType) {
  const {
    id,
    name,
    logo,
    number_of_members,
    isAdmin,
    updateOrganization,
    deleteOrganization,
  } = props;

  return (
    <Row className={styles.organization_item_wrapper}>
      <Row className={styles.organization_info}>
        <img
          className={styles.organization_logo}
          src={logo ? logo : '/Images/logo.png'}
          alt=''
          onError={(e) => {
            (e.target as HTMLImageElement).src = '/Images/logo.png';
          }}
        />
        <div>
          <div className={styles.organization_name}>{name}</div>
          <div className={styles.org_num_of_mem}>
            Số thành viên:{' '}
            {number_of_members < 10
              ? `0${number_of_members}`
              : number_of_members}
          </div>
        </div>
      </Row>
      <Row style={{ fontWeight: '600' }}>
        <Link
          className={styles.action_color}
          to={`/organization-list/organization-info/${id}`}
        >
          Chi tiết
        </Link>
        <div
          className={`${styles.action_modify} ${styles.action_color}`}
          onClick={updateOrganization}
        >
          Sửa
        </div>
        {isAdmin && (
          <div className={styles.action_delete} onClick={deleteOrganization}>
            Xóa
          </div>
        )}
      </Row>
    </Row>
  );
}
