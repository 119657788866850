import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import styles from './department.module.css';

interface DepartmentInfoType {
  id: string;
  name: string;
  numberOfMembers: number;
  managerName?: string;
  managerId?: string;
  namecode?: string;
}

interface DepartmentItemPropType extends DepartmentInfoType {
  updateDepartment: () => void;
  deleteDepartment: () => void;
  orgId: string;
}

export default function DepartmentItem(props: DepartmentItemPropType) {
  const {
    name,
    numberOfMembers,
    managerName,
    managerId,
    namecode,
    updateDepartment,
    deleteDepartment,
    orgId,
  } = props;

  return (
    <Row style={{ marginBottom: 20 }}>
      <Col span={11}>
        <div className={styles.department_text_bold}>{name}</div>
        <div className={styles.department_number_of_members}>
          Mã: {namecode}
        </div>
        <div className={styles.department_number_of_members}>
          Số thành viên:{' '}
          {numberOfMembers < 10 ? `0${numberOfMembers}` : numberOfMembers}
        </div>
      </Col>
      <Col span={9}>
        <div className={styles.department_text_bold}>Trưởng phòng</div>
        {managerId && (
          <Link
            style={{ fontWeight: 700, fontSize: 13 }}
            to={`/organization-list/organization-info/${orgId}/member-info/${managerId}`}
          >
            {managerName}
          </Link>
        )}
      </Col>
      <Col span={4}>
        <Row style={{ fontWeight: '600' }}>
          <div
            className={`${styles.action_modify} ${styles.action_color}`}
            onClick={updateDepartment}
          >
            Sửa
          </div>
          <div className={styles.action_delete} onClick={deleteDepartment}>
            Xóa
          </div>
        </Row>
      </Col>
    </Row>
  );
}
