import { Col, Row } from 'antd';

import styles from './group.module.css';

interface GroupInfoType {
  id: string;
  name: string;
  numberOfMembers: number;
}

interface GroupItemPropType extends GroupInfoType {
  updateGroup: () => void;
  deleteGroup: () => void;
}

export default function GroupItem(props: GroupItemPropType) {
  const { name, numberOfMembers, updateGroup, deleteGroup } = props;

  return (
    <Row style={{ marginBottom: 20 }}>
      <Col span={20}>
        <div className={styles.group_name}>{name}</div>
        <div className={styles.group_number_of_members}>
          Số thành viên:{' '}
          {numberOfMembers < 10 ? `0${numberOfMembers}` : numberOfMembers}
        </div>
      </Col>
      <Col span={4}>
        <Row style={{ fontWeight: '600' }}>
          <div
            className={`${styles.action_modify} ${styles.action_color}`}
            onClick={updateGroup}
          >
            Sửa
          </div>
          <div className={styles.action_delete} onClick={deleteGroup}>
            Xóa
          </div>
        </Row>
      </Col>
    </Row>
  );
}
