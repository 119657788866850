import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { Button } from 'antd';
import { ResponseDataType, deleteRequest } from 'api';

import { popupStyles } from 'Component';
import { toast } from 'react-toastify';

interface DeleteDepartmentPropsType {
  departmentId: string;
  close: () => void;
  getDepartmentList: () => void;
}

export default function DeleteDepartmentPopup(
  props: DeleteDepartmentPropsType
) {
  const { departmentId, close, getDepartmentList } = props;

  const DeleteDepartment = async () => {
    const response: ResponseDataType<string> = await deleteRequest(
      `/organization/delete-department/${departmentId}`
    );

    if (response.code) {
      toast.error(response.message);
      close();
    } else {
      toast.success('Xóa phòng ban thành công');
      close();
      getDepartmentList();
    }
  };

  return (
    <>
      <div style={{ color: '#FF0303' }} className={popupStyles.popup_title}>
        Xóa phòng ban
      </div>
      <ErrorOutlinedIcon sx={{ color: '#FF0303', fontSize: 64 }} />
      <div
        style={{ color: '#FF0303', fontWeight: 700, margin: '20px 0 10px 0' }}
      >
        BẠN CÓ CHẮC CHẮN MUỐN
      </div>
      <div style={{ color: '#FF0303', fontWeight: 700, fontSize: 20 }}>
        XÓA PHÒNG BAN
      </div>
      <div className={popupStyles.form_button_wrapper}>
        <Button
          type='primary'
          danger
          className={`${popupStyles.form_button}`}
          onClick={DeleteDepartment}
        >
          Xóa phòng ban
        </Button>
        <Button
          type='primary'
          className={`${popupStyles.form_button} ${popupStyles.form_button_cancel}`}
          onClick={close}
        >
          Huỷ
        </Button>
      </div>
    </>
  );
}
