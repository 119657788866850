import { Table, TableProps } from 'antd';

import styles from '../organization.module.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ResponseDataType, getRequest } from 'api';

interface OrganizationDataType {
  id: string;
  info: { name: string; logo?: string };
  number_of_members: number;
  number_of_invited_members: number;
  number_of_quit_members: number;
  owner: string;
}

interface OrganizationResponseDataType extends OrganizationDataType {
  key: number;
}

const columns: TableProps<OrganizationDataType>['columns'] = [
  {
    title: 'Tên tổ chức',
    dataIndex: 'info',
    key: 'info',
    width: '300px',
    render: (info: { name: string; logo?: string }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          style={{
            height: 30,
            width: 30,
            marginRight: 10,
            borderRadius: '50%',
          }}
          src={info.logo ? info.logo : '/Images/logo.png'}
          alt=''
          onError={(e) => {
            (e.target as HTMLImageElement).src = '/Images/logo.png';
          }}
        />
        {info.name}
      </div>
    ),
  },
  {
    title: 'Số thành viên',
    dataIndex: 'number_of_members',
    key: 'number_of_members',
    width: '200px',
  },
  {
    title: 'Đang mời',
    dataIndex: 'number_of_invited_members',
    key: 'number_of_invited_members',
    width: '200px',
  },
  {
    title: 'Đã nghỉ',
    dataIndex: 'number_of_quit_members',
    key: 'number_of_quit_members',
    width: '200px',
  },
  {
    title: 'Quản trị viên',
    dataIndex: 'owner',
    key: 'owner',
  },
  {
    title: 'Hành động',
    dataIndex: 'id',
    key: 'action',
    width: '140px',
    render: (id) => (
      <Link to={`/organization-list/organization-info/${id}`}>Chi tiết</Link>
    ),
  },
];

export default function OrganizationList() {
  const [organizationList, setOrganizationList] =
    useState<OrganizationResponseDataType[]>();

  useEffect(() => {
    const GetOrganizationList = async () => {
      const response: ResponseDataType<OrganizationResponseDataType[]> =
        await getRequest('/organization/organization-list');

      setOrganizationList(response.data);
    };

    GetOrganizationList();
  }, []);

  return (
    <div className={styles.organization_list_wrapper}>
      <Table columns={columns} dataSource={organizationList} />
    </div>
  );
}
